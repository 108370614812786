<template>

    <el-dialog custom-class="creditDialog" :title="title" v-loading="IsDeductingCredit" :width="'600px'" v-model="IsVisible">
        <el-row v-loading="IsDeductingCredit" v-if="subscription !== null">
            <el-col :span="24">
                <p v-if="subscription.subscriptionType !== 'Grouped' && subscription.totalCredits > 0">               
                    <span>
                        Once the credit is deducted you will be able to run as many different reports for {{client.forename + ' ' + client.surname}} as you like without additional credits being taken .
                    </span>
                </p>
                <p v-if="subscription.subscriptionType !== 'Grouped' && subscription.totalCredits <= 0">We will add {{renewalAmount}} additional credits to your report on {{computedRenewalDate}}. If you would like to purchase additional credits to run the result before this date click below.</p>
                <p v-if="subscription.subscriptionType === 'Grouped' && subscription.totalCredits <= 0">Apologies, your company license has now reached its credit limit. Until additional credits are added you will not be able to generate results.</p>
                <p v-if="subscription.subscriptionType === 'Grouped' && subscription.totalCredits <= 0">We have contacted your company to inform them of the situation and if you would like to learn more, please contact the account holder.</p>
                <div v-if="subscription.subscriptionType !== 'Grouped' || subscription.totalCredits > 0" class="CustomerDetails">
                    <strong>Client Details</strong><br />
                    {{client.forename}} {{client.surname}}<br />
                    {{helper.GetDisplayableDate(client.dateOfBirth)}}<br />
                    {{helper.GetGender(client.classification)}}
                </div>
                <p style="text-align:right">
                    <el-button type="default" @click="IsVisible=false">
                        <i class="el-icon-arrow-left"></i>
                        Back
                    </el-button>
                    <el-button type="primary" @click="MoreCredits" v-if="subscription.subscriptionType !== 'Grouped' && subscription.totalCredits <= 0">
                        Add Credits
                    </el-button>
                    <el-button type="primary" @click="DeductCredit" v-if="subscription.subscriptionType === 'Grouped' && subscription.totalCredits > 0" :disabled="isSubscriptionLoading">
                        Continue
                    </el-button>
                    <el-button type="primary" @click="DeductCredit" v-if="subscription.subscriptionType !== 'Grouped' && subscription.totalCredits > 0" :disabled="isSubscriptionLoading">
                        Continue
                    </el-button>
                </p>
            </el-col>
        </el-row>
    </el-dialog>
</template>
<style>
    .creditDialog .el-dialog__body {
        padding-top: 0 !important;
    }

    p {
        color: #4B5768;
    }
</style>
<style scoped>
    button {
        width: unset;
    }

    .CustomerDetails {
        background: #F6F6F6;
        font-size: 16px;
        padding: 16px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
    }
</style>
<script>
    import helper from './HelperMethods.vue';
    export default {
        name: 'ConfirmCredits',
        props: ["client", "customerType", "customerRef", "productType", "legacyPolicyProviderReference", "toolName", "legacyProductName", "analysisReferecnce", "sessionRef"],
        inject: ["SubscriptionService"],
        data: function () {
            return {
                IsVisible: true,
                IsDeductingCredit: false,
                helper: helper,
                subscription: null,
                isSubscriptionLoading: false,
                legacyPolicyReference: this.legacyPolicyProviderReference
            }
        },
        mounted: function () {
            this.subscription = this.SubscriptionService.getSubscription();
            this.isSubscriptionLoading = this.SubscriptionService.isloading();
        },
        watch: {
            IsVisible: function (newValue) {
                if (newValue === false) {
                    this.$emit('deduct-credits-cancelled');
                }
            },
        },
        methods: {
            MoreCredits: function () {
                var thisControl = this;
                thisControl.IsDeductingCredit = true;
                thisControl.SubscriptionService.getAdditionalCredits()
                    .then(function () {
                        thisControl.IsDeductingCredit = false;
                    });
            },
            DeductCredit: function () {
                var thisControl = this;
                thisControl.$emit('deduct-credit-succeeded');
            }
        },
        computed:
        {
            title: {
                get: function () {
                    if (this.subscription === null)
                        return "";
                    if (this.subscription.totalCredits > 0) {
                        return "Generate results";
                    }
                    this.else
                    {
                        if (this.subscription.subscriptionType !== 'Grouped') {
                            return "Add credits to download this report​"
                        }
                        else {
                            return "Insufficient credits​";
                        }
                    }
                }
            },
            computedRenewalDate: {
                get: function () {
                    if (this.subscription === null)
                        return "";
                    return helper.GetDisplayableDate(this.subscription.renewalDate);
                }
            },
            renewalAmount: {
                get: function () {
                    if (this.subscription === null)
                        return "";
                    return this.subscription.additionalCreditsAtExpiration;
                }
            }
        },
    }</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
