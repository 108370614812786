<template>
    <loading-control v-if="isRedirecting" loading-message = "We are preparing all to send you to UnderWrite Me" />
    <div v-if="!isRedirecting">
        <div class="holder">
            <div class="Verification">

                <advertisement v-if="advert !== null" :advert="advert" loading-message="Loading..." :user-selection="UserSelection" screen="Express Questions" />
                <loading-control v-if="isLoading && advert === null" :loading-message="'loading...'" />

                <div v-if="!isLoading">
                    <div class="">
                        <h1 class="Title">Is all this information correct?</h1>
                    </div>
                    <div v-if="this.UserSelection.underwritten">
                        <p style="font-size: small;">Please check the information carefully. If this is changed within the UnderwriteMe screens we will need to create a new analysis.</p>
                    </div>

                    <div>
                        <div class="Paragrapgh03">
                            <label class="labels">Policy type</label>
                        </div>
                        <div class="PolicyTypeCard">
                            <div class="detail">
                                <label>{{UserSelection.tool.toolName}}</label>
                            </div>
                            <div class="links">
                                <el-link v-on:click.stop="$emit('load-tool-selection')">Edit</el-link>
                            </div>
                        </div>

                        <div class="Paragrapgh03">
                            <label class="labels">Client details</label>

                        </div>
                        <div class="ClientDetailsCard">
                            <div class="detail">
                                <div v-if="UserSelection.primaryClient !== null">
                                    {{UserSelection.primaryClient.forename + " " + UserSelection.primaryClient.surname}}
                                    <br />
                                    {{helper.GetDisplayableDate(UserSelection.primaryClient.dateOfBirth)}} , {{helper.GetDisplayableClassification(UserSelection.primaryClient.classification)}}
                                </div>

                                <div v-if="UserSelection.secondaryClient !== null">
                                    {{UserSelection.secondaryClient.forename + " " + UserSelection.secondaryClient.surname}}
                                    <br />
                                    {{helper.GetDisplayableDate(UserSelection.secondaryClient.dateOfBirth)}} , {{helper.GetDisplayableClassification(UserSelection.secondaryClient.classification)}}
                                </div>

                            </div>
                            <div class="links">
                                <el-link v-on:click.stop="$emit('edit-customer')">Edit</el-link>
                            </div>
                        </div>

                        <div class="Paragrapgh03">
                            <label class="labels">Type of analysis</label>
                        </div>

                        <div class="PolicyTypeCard" v-if="this.UserSelection.legacyPolicyProvider !== ''">
                            <div class="detail">
                                <label class="summaryDetail">
                                    Historic policy comparison
                                    <br />
                                    {{ this.UserSelection.legacyPolicyProvider }}
                                </label><br />
                            </div>
                            <div class="links">
                                <el-link v-on:click.stop="$emit('policy-details-added')">Edit</el-link>
                            </div>
                        </div>

                        <div class="PolicyTypeCard">
                            <div class="detail">
                                <label class="summaryDetail">{{UserSelection.planType}}</label>
                            </div>

                            <div class="links">
                                <el-link v-on:click.stop="$emit('ci-Policy-Details-Added')">Edit</el-link>
                            </div>
                        </div>

                        <div class="Paragrapgh03">
                            <label class="labels">Additional client information</label>
                        </div>
                        <div class="ClientDetailsCard">
                            <!--Getting Selected Answers of Additional Info Screen but no getting as per Mo's Design check below-->
                            <div class="detail">

                                <!--<div class="detail" v-for="(expressQuestion) in ExpressCustomFields" :key="expressQuestion.displayQuestion">
                                    <label>{{UserSelection.customFields[expressQuestion.displayQuestion]}}</label>
                                </div>-->
                            </div>
                            <div class="links">
                                <el-link v-on:click.stop="$emit('ta-express-question-selected')">Edit</el-link>
                            </div>
                        </div>

                        <div v-if="UserSelection.planType === 'Custom'">
                            <div class="Paragrapgh03" v-for="cat in AvailableCategories" :key="cat.category">
                                <label class="labels">{{ cat.category }}</label>
                                <div class="PolicyTypeCard" v-for="feature in GetFeatuesByCategory(cat)" :key="feature.featureReference">
                                    <div class="detail">
                                        <label class="summaryDetail">{{ feature.featureName}} <br /> Level of importance: {{ feature.weight}} </label>
                                    </div>
                                    <div class="links">
                                        <el-link v-on:click.stop="$emit('back')">Edit</el-link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="btnNextPosition">
                        <el-button type="default" v-on:click.stop="$emit('back')"> <span class="el-icon-arrow-left"></span> Back</el-button>
                        <el-button v-if="this.UserSelection.underwritten" type="success" :disabled="!canProceed" @click.stop="GoToUnderWriteMe">Complete underwriting information</el-button>
                        <el-button v-else type="success" :disabled="!canProceed" @click.stop="GetAnswers">Get results</el-button>
                    </div>
                </div>
            </div>
            <confirm-credits v-if="toRunAfterDeducted !== null"
                            @deduct-credits-cancelled="toRunAfterDeducted = null"
                            @deduct-credit-succeeded="toRunAfterDeducted()"
                            :client="UserSelection.primaryClient"
                            :customer-ref="UserSelection.customerReference"
                            :product-type="'Legacy' + UserSelection.legacyPolicyProviderReference"
                            :customer-type="customerType"
                            :legacyPolicyProviderReference="UserSelection.legacyPolicyProviderReference"
                            :toolName="UserSelection.tool.toolName"
                            :legacyProductName="UserSelection.legacyPolicyProvider" />
        </div>
    </div>
</template>

<style type="text/css" scoped>
    /* ipad Mini Landscape */
    @media only screen and (width:1024px) {
        .Verification {
            margin-left: -110px;
        }
    }

    .holder {
        max-width: 1144px;
        min-width: 1144px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 24px;
        position: relative;
    }

    .links {
        text-decoration: underline;
        margin-left: auto;
        margin-right: 0;
        /*text-align:right;*/
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
    }

    .PolicyTypeCard {
        width: 330px;
        left: 82px;
        /*margin-top: 10px;*/
        background: #F6F6F6;
        padding: 16px;
        display: inline-flex;
    }

    .el-link.el-link--default:hover {
        color: black !important;
    }

    .el-link.is-underline:hover:after {
        border: none;
    }

    .el-link.el-link--default:after {
        border: none;
    }

    .Paragrapgh03 {
        font-family: 'Inter';
        font-style: normal;
        /*font-weight: 600;*/
        font-size: 12px;
        color: #0F172A;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin-top: 16px;
    }

    .ClientDetailsCard {
        width: 330px;
        left: 82px;
        background: #F6F6F6;
        padding: 16px;
        display: inline-flex;
    }

    .detail {
        font-family: 'Inter';
        font-style: normal;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;
    }

    .summaryLabels {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 56px;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;
        color: #A0A0A0;
    }

    .summary {
        position: absolute;
        width: 232px;
        height: 572px;
        margin-left: 944px;
        margin-top: 211px;
    }

    .summaryTitle {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 41px;
        letter-spacing: -0.04em;
        color: #0F172A;
    }

    .btnNextPosition {
        display: inline-flex;
        padding-top: 20px;
        padding-left: 10px;
    }

    .el-button {
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        align-items: center !important;
        padding-top: 10px !important;
        position: static !important;
        width: 167px !important;
        height: 44px !important;
        border-radius: 8px !important;
        float: right !important;
        background: #059669 !important;
        border-color: #059669 !important;
    }

    .el-button--success:focus, .el-button--success:hover {
        background: #059669 !important;
        border-radius: 8px !important;
        /*border-color: #059669 !important;*/
    }

    .el-button--success.is-disabled, .el-button--success.is-disabled:active, .el-button--success.is-disabled:focus, .el-button--success.is-disabled:hover {
        background: #E7EAEE !important;
        border-radius: 8px !important;
        /*border-color: #E7EAEE !important;*/
        font-style: normal !important;
    }

    .el-button--success {
        color: #fff !important;
        background: #059669 !important;
        font-size: small !important;
        white-space: normal !important;
        /*border-color: #059669 !important;*/
    }

    .el-button--default {
        color: black !important;
        background-color: white !important;
        border: 1px solid #191D23 !important;
    }

    /*.el-button--default:focus, .el-button--default:hover {
            background: white;
            border-radius: 8px;
            border: 1px solid #191D23;
            border-radius: 8px;
            color: black;
        }*/

    .el-button--success.is-disabled, .el-button--success.is-disabled:active, .el-button--success.is-disabled:focus, .el-button--success.is-disabled:hover {
        background: #E7EAEE !important;
        border-radius: 8px !important;
        /*border-color: #E7EAEE !important;*/
        font-style: normal !important;
    }

    .Verification {
        padding: 82px;
        width: 530px;
        /*height: 777px;*/
        left: 530px;
        top: 110px;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .Title {
        width: 366px;
        height: 20px;
        left: 0px;
        top: 66px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        /*line-height: 34px;*/
        letter-spacing: -0.02em;
        color: #0F172A;
    }


    .labels {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px !important;
        line-height: 24px;
        color: black;
        margin-top: 15px;
    }
</style>

<script>
    import LoadingControl from '../components/LoadingControl.vue';
    import Advertisement from '../components/Advertisement.vue';
    import helper from '../components/HelperMethods.vue';
    import $ from 'jquery';
    import ConfirmCredits from '../components/ConfirmCredits.vue';

    export default
        {
            components: { LoadingControl, Advertisement, ConfirmCredits },
            inject: ["SubscriptionService"],
            name: 'IncomeProtectionExpressQuestion',
            props: ["userSelection", "userDetails", "templateSet", "adverts"],
            data: function () {
                return {
                    helper: helper,
                    ShowingQuestions: false,
                    skipTemplates: false,
                    advert: null,
                    isLoading: false,
                    isRedirecting: false,
                    toRunAfterDeducted: null,
                    customerType: null,
                }
            },
            methods:
            {
                GetFeatuesByCategory: function (cat) {
                    return this.UserSelection.extendedFeatures.filter(function (feature) { return feature.featureCategory == cat.category && feature.weight > 0; });
                },
                GetAnswers: function () {
                    var thisControl = this;
                    thisControl.toRunAfterDeducted = null;
                    //if (thisControl.UserSelection.showCIPolicy === "Yes" && thisControl.UserSelection.legacyPolicyProviderReference != '') {
                        //if ((thisControl.UserSelection.termBenefitAmount !== null && thisControl.UserSelection.termBenefitAmount !== '') || (thisControl.UserSelection.termCriticalIllnessCoverAmount !== null && thisControl.UserSelection.termCriticalIllnessCoverAmount !== '') || (thisControl.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount !== null && thisControl.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount !== '')) {
                            thisControl.SubscriptionService.hasCredit(thisControl.userSelection.customerReference, thisControl.userSelection.selectedCustomerType).then(function (hasCredit) {
                                if (hasCredit) {
                                    if (thisControl.UserSelection.planType !== 'Custom') {
                                        thisControl.$emit('express-question-selected');
                                    }
                                    else
                                        thisControl.$emit('custom-route-selected');                           
                                }
                                else {
                                    thisControl.toRunAfterDeducted = function () {
                                        if (thisControl.UserSelection.planType !== 'Custom') {
                                            var values = helper.GetValuesForCondtion(thisControl.UserSelection);
                                            var matchingOptions = thisControl.ExpressTemplate.options.filter(function (c) {
                                                return helper.checkCondition(c.condition, values);
                                            });
                                            if (matchingOptions.length > 0) {
                                                thisControl.UserSelection.expressTemplate = matchingOptions[0];
                                                thisControl.UserSelection.extendedFeatures.forEach(function (f) { f.weight = 0; });
                                                thisControl.UserSelection.categoryWeightings.forEach(function (c) { c.weight = 0; });
                                            }
                                            else {
                                                thisControl.UserSelection.expressTemplate = null;
                                            }
                                            thisControl.$emit('express-question-selected');
                                        }
                                        else
                                            thisControl.$emit('custom-route-selected');
                                    }
                                }
                            });
                        //}
                    //}
                },
                GoToUnderWriteMe: function () {
                    var thisControl = this;

                    if (thisControl.UserSelection.planType == 'Custom') {
                        thisControl.isRedirecting = true;

                        $.ajax({
                            type: 'POST',
                            url: "/Api/",
                            contentType: 'application/json',
                            data: JSON.stringify(thisControl.userSelection),
                            success: function (data) {
                                window.open(data.url, '_blank').focus();
                                thisControl.isRedirecting = false;
                                thisControl.UserSelection.analysisReference = data.analysisReference;
                                //thisControl.LoadAnalysis(data.customerReference, data.analysisReference);
                                window.location.href = "/";
                            },
                            error: function () {
                                thisControl.isRedirecting = false;
                                thisControl.$message.error("We could not create the UnderWrite me application.");
                                window.location.href = "/";
                            }
                        });
                    }
                    else {
                        thisControl.UserSelection.goToUnderWriteMe = true;
                        this.GetAnswers();
                    }
                },
                LoadAnalysis: function (customerReference, analysisReference) {
                    var thisControl = this;
                    thisControl.UserSelection.customerReference = customerReference;

                    $.ajax({
                        type: 'GET',
                        url: '/CustomerAnalysis/' + thisControl.UserSelection.customerReference + '/' + analysisReference,
                        contentType: 'application/json',
                        success: function (data) {
                            if (data.userSelection !== null)
                            {
                                if (data.analysis.analysisReferecnce === undefined) 
                                    data.analysis.analysisReferecnce = analysisReference;
                                thisControl.$emit('analysis-selected', data.userSelection, data.analysis);
                            }
                        }
                    });
                },
            },
            mounted: function () {
                var waiting = 1;
                this.isLoading = true;
                var thisControl = this;
                var done = function () {
                    thisControl.isLoading = false;
                    thisControl.advert = null;
                    var template = thisControl.ExpressTemplate;
                    if (template !== null) {

                        var oldValues = thisControl.UserSelection.customFields;
                        var newValues = {};

                        for (var customFieldsIndex = 0; customFieldsIndex < template.customFields.length; customFieldsIndex++) {
                            var fieldName = template.customFields[customFieldsIndex].displayQuestion;

                            if (typeof (oldValues[fieldName]) === 'string') {
                                newValues[fieldName] = oldValues[fieldName];
                                thisControl.ShowingQuestions = true;
                            }
                            else
                                newValues[fieldName] = null;
                        }
                        thisControl.UserSelection.customFields = newValues;
                    }

                }
                var adToShow = helper.GetAdvert(this.adverts, "ExpressTemplateScreen", this.UserSelection);
                if (adToShow !== null) {
                    this.advert = adToShow;
                    waiting++;
                    setTimeout(function () {
                        waiting--;
                        if (waiting == 0)
                            done();
                    }, adToShow.minimumDurationInSeconds * 1000);
                }

                $.ajax({
                    type: 'GET',
                    url: '/api/Journey/Update/' + this.UserSelection.sessionReference + "/" + this.UserSelection.journeyReference + "/10/Express%20Question",
                    success: function () {
                        waiting--;
                        if (waiting == 0)
                            done();
                    },
                });

                var customerLoaded = function (c) {
                    var isClient1 = (
                        c.life1FirstName === thisControl.userSelection.primaryClient.forename
                        && c.life1LastName === thisControl.userSelection.primaryClient.surname
                        && c.life1Gender === helper.GetGender(thisControl.userSelection.primaryClient.classification)
                        && helper.GetDisplayableDate(c.life1DateOfBirth) === helper.GetDisplayableDate(thisControl.userSelection.primaryClient.dateOfBirth)
                    )
                    if (isClient1) {
                        thisControl.isValidCustomer = true;
                        thisControl.customerType = "PersonOne";
                        if (thisControl.userSelection.secondaryClient !== null) {
                            thisControl.customerType = "Both";
                        }
                    }
                    var isClient2 = (
                        c.life2FirstName === thisControl.userSelection.primaryClient.forename
                        && c.life2LastName === thisControl.userSelection.primaryClient.surname
                        && c.life2Gender === helper.GetGender(thisControl.userSelection.primaryClient.classification)
                        && helper.GetDisplayableDate(c.life2DateOfBirth) === helper.GetDisplayableDate(thisControl.userSelection.primaryClient.dateOfBirth)
                    )
                    if (isClient2) {
                        thisControl.isValidCustomer = true;
                        thisControl.customerType = "PersonTwo";
                    }
                    console.log(c);
                }

                fetch("/Customer/" + thisControl.UserSelection.customerReference)
                    .then(function (c) { return c.json() })
                    .then(function (c) {
                        customerLoaded(c);
                        thisControl.IsLoading = false;
                        thisControl.advert = null;
                    }
                    ).catch(function () {
                        thisControl.IsLoading = false;
                        thisControl.advert = null;
                    });
                return;
            },
            watch: {
                skipTemplates: function () {
                    var thisControl = this;
                    thisControl.UserSelection.expressTemplate = null;
                    thisControl.$emit('express-question-selected');
                }
            },
            computed: {
                UserSelection: function () { return this.userSelection; },
                UserDetails: function () { return this.userDetails; },
                TemplateSet: function () { return this.templateSet; },
                canProceed: function () {

                    if (this.UserSelection.planType === 'Custom')
                        return true;

                    var template = this.ExpressTemplate;
                    if (template !== null) {
                        for (var customFieldsIndex = 0; customFieldsIndex < template.customFields.length; customFieldsIndex++) {
                            var fieldName = template.customFields[customFieldsIndex].displayQuestion;

                            if (typeof (this.UserSelection.customFields[fieldName]) !== 'string') {
                                return false;
                            }
                        }
                        return true;
                    }

                    return false;
                },
                ExpressTemplate: {
                    get: function () {
                        var thisControl = this;
                        if (thisControl.TemplateSet !== null) {

                            var templates;

                            if (thisControl.UserSelection.legacyPolicyProviderReference !== null && thisControl.UserSelection.legacyPolicyProviderReference !== '') {
                                templates = thisControl.TemplateSet.toolTemplates.filter(function (t) {
                                    return t.toolKey === "Legacy";
                                });
                            }
                            else {
                                templates = thisControl.TemplateSet.toolTemplates.filter(function (t) {
                                    return t.toolKey === thisControl.UserSelection.tool.toolReference;
                                });
                            }

                            if (templates.length > 0)
                                return templates[0];
                        }
                        return null;

                    }
                },
                ExpressCustomFields: {
                    get: function () {
                        if (this.ExpressTemplate === null)
                            return [];
                        if (this.ExpressTemplate.customFields === null) {
                            return [];
                        }
                        return this.ExpressTemplate.customFields;
                    }
                },
                "AvailableCategories": function () {
                    return this.UserSelection.categoryWeightings.filter(function (category) { return category.weight > 0 });
                }
            }
        }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
